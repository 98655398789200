html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  -ms-scroll-chaining: none;
  overscroll-behavior: none; /* Prevents scroll bubbling */
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html,
body,
button,
div,
p,
h1,
img,
input {
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
}

@font-face {
  font-family: "Montserrat";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
}
@font-face {
  font-family: "Montserrat-Light";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
}
@font-face {
  font-family: "Montserrat-Extra-Light";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
body {
  overflow: visible;
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none !important;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input,
select {
  vertical-align: middle;
}
body {
  font-family: "Montserrat-Medium", sans-serif;
}
*,
:after,
:before {
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
